import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUp } from "@fortawesome/free-solid-svg-icons";

function App() {
  useEffect(() => {
    const header = document.querySelector("#header");
    let scrolling = 0;

    window.addEventListener("scroll", () => {
      scrolling = window.scrollY;
      if (scrolling >= 100) {
        header.style.background = "var(--accent1)";
      } else {
        header.style.background = "var(--accent1O)";
      }
    });
  });

  return (
    <>
      <div id="header">
        <Link to="/">
          <div className="header-title">
            <div className="logo small">
              <img src="./images/SmallLogoSVG.svg" alt="Business logo" />
            </div>
            <div>
              <div className="name">Next Generation Forestry</div>
              <div className="subtitle">
                Serving the Willamette Valley in Salem, OR and Surrounding Areas
              </div>
            </div>
          </div>
        </Link>

        <div className="header-links">
          <Link to="/services">Services</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/testimonials">Testimonials</Link>
        </div>
      </div>

      <div id="top">
        <div className="top-blurb">
          <div className="blurb-header">What Is a Consulting Forester?</div>
          <div className="divider"></div>
          <div className="blurb">
            A Consulting Forester is an independent natural resource advisor
            working for the landowner to give advice and counsel on all natural
            resource matters. The clients we choose to work with feel confident
            that we always have their best interests in mind. If we do not have
            the answers, we help the landowners find the answers, even if it
            means utilizing other Consulting Foresters.
          </div>
        </div>
      </div>

      <div id="topic1" className="body left">
        <div className="topic-blurb">
          <div className="blurb-header">Code of Ethics</div>
          <div className="divider"></div>
          <div className="blurb">
            <p>
              We do not take on every job that is offered to us. We go through a
              thoughtful process to make sure we are the right fit for the
              potential clients before entering into a contract. Once we enter
              into a contract with a client, we will provide professional
              assistance to persons without discrimination on the basis of race,
              age, ethnicity, socioeconomic status, disability, gender, health
              status, religion, national origin, sexual orientation, gender
              identity or relationship status. We expect our clients to follow
              the same ethical standards as us. We also take pride in serving
              members of under-served communities.
            </p>
          </div>
        </div>

        <div className="topic1-picture topic-picture code-of-ethics"></div>
      </div>

      <div id="topic2" className="body right">
        <div className="topic2-picture topic-picture about-us"></div>

        <div className="topic-blurb">
          <div className="blurb-header">About Us & Our Mission</div>
          <div className="divider"></div>
          <div className="blurb">
            <p>
              We, Casara and Sam, are both owners of this company and have a
              combined 29 years of experience in all aspects of forestry and
              natural resources management. We are proficient in Forest
              Management Plans, timber purchasing, timber sales, contract
              writing and administration for all types of forestry contracts,
              all aspects of reforestation, timber cruising and appraisals, GPS
              and GIS, harvest unit and road design, economic feasibility
              studies, logging, and equipment operation.
            </p>
            <br />
            <p>
              We believe it's important that our clients feel confident in their
              land management choices, and we strive to educate them through all
              steps of their management process. Together we pride ourselves in
              teaching landowners, not just doing the work. We have earned the
              trust of our clients, and they have all learned so much more about
              forestry and their land than they knew before.
            </p>
          </div>
        </div>
      </div>

      <div id="topic-bases" className="body">
        <div className="topic-blurb">
          <div className="blurb-header">Client Base</div>
          <div className="divider"></div>
          <div className="blurb">
            <div className="base-section">
              <div className="image-base image1"></div>
              <span>Small Private Landowners</span>
            </div>
            <div className="base-section">
              <div className="image-base image2"></div>
              <span>Local, State, and Federal Agencies</span>
            </div>
            <div className="base-section">
              <div className="image-base image3"></div>
              <span>Non-Profit Organizations</span>
            </div>
            <div className="base-section">
              <div className="image-base image4"></div>
              <span>Other Consulting Foresters</span>
            </div>
          </div>
        </div>
      </div>

      <div id="footer">
        <div className="footer-address">
          <span>P.O. Box 4690</span>
          <span>Salem, OR 97302</span>
          <span>Office Hours: 8AM - 4PM PST</span>
        </div>

        <div className="footer-top">
          <a href="#top">
            <FontAwesomeIcon icon={faCircleUp} />
          </a>
        </div>
      </div>
    </>
  );
}

export default App;

/*
  USE
  npm run start     //to test
  npm run build     //to build project folder
  firebase deploy   //to deploy
*/
