import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUp } from "@fortawesome/free-solid-svg-icons";

function Testimonials() {

  useEffect(() => {
    const header = document.querySelector("#header-page");
    let scrolling = 0;

    window.addEventListener("scroll", () => {
      scrolling = window.scrollY;
      if (scrolling >= 75) {
        header.style.background = "var(--accent1)";
      } else {
        header.style.background = "var(--accent1O)";
      }
    })
  });

  return (
    <>
      <div id="header-page">
        <Link to="/">
          <div className="header-title">
            <div className="logo small">
              <img src="./images/SmallLogoSVG.svg" alt="Business logo" />
            </div>
            <div>
              <div className="name">Next Generation Forestry</div>
              <div className="subtitle">
                Serving the Willamette Valley in Salem, OR and Surrounding Areas
              </div>
            </div>
          </div>
        </Link>

        <div className="header-links">
          <Link to="/services">Services</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/testimonials">Testimonials</Link>
        </div>
      </div>

      <div id="top-pages"></div>

      <div id="topic1" className="body left">
        <div className="topic-blurb">
          <div className="blurb-header">Dement Ranch, LLC, Curry County, OR</div>
          <div className="divider"></div>
          <div className="blurb">
            <p>
              My husband hired Next Generation Forestry in the spring of 2021
              and passed away that December. The following is a brief
              description of how much I appreciate the services provided by Next
              Generation Forestry. After my husband's passing, you graciously
              helped me navigate through the ranch timber operations that were
              planned. You and your team were invaluable in getting me through
              that year as I knew little about the process. I trust you
              implicitly as you have been nothing but honest and reliable in all
              areas. I will sorely miss your expertise and friendship as you and your family move to a different location.
            </p>
          </div>
        </div>

        <div className="topic1-picture topic-picture dement-ranch"></div>
      </div>

      <div id="topic2" className="body right">
        <div className="topic2-picture topic-picture curry-county"></div>

        <div className="topic-blurb">
          <div className="blurb-header">Client in Curry County, OR</div>
          <div className="divider"></div>
          <div className="blurb">
            <p>
              You approach a harvest project like it is your own, from
              establishing lucrative harvest boundaries, negotiating great
              timber prices, getting the right contractors, to finding seedlings
              to getting the reforestation done. With you there is high
              confidence that the harvest project will be a success for the
              landowner.
            </p>
          </div>
        </div>
      </div>

      <div id="footer-page">
        <div className="footer-address">
          <span>P.O. Box 4690</span>
          <span>Salem, OR 97302</span>
          <span>Office Hours: 8AM - 4PM PST</span>
        </div>

        <div className="footer-top">
          <a href="#header-page">
            <FontAwesomeIcon icon={faCircleUp} />
          </a>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
