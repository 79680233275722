import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUp,
  faMagnifyingGlassPlus,
} from "@fortawesome/free-solid-svg-icons";

function Services() {

  useEffect(() => {
    const header = document.querySelector("#header-page");
    let scrolling = 0;

    window.addEventListener("scroll", () => {
      scrolling = window.scrollY;
      if (scrolling >= 75) {
        header.style.background = "var(--accent1)";
      } else {
        header.style.background = "var(--accent1O)";
      }
    })
  });

  return (
    <>
      <div id="header-page">
        <Link to="/">
          <div className="header-title">
            <div className="logo small">
              <img src="./images/SmallLogoSVG.svg" alt="Business logo" />
            </div>
            <div>
              <div className="name">Next Generation Forestry</div>
              <div className="subtitle">
                Serving the Willamette Valley in Salem, OR and Surrounding Areas
              </div>
            </div>
          </div>
        </Link>

        <div className="header-links">
          <Link to="/services">Services</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/testimonials">Testimonials</Link>
        </div>
      </div>

      <div id="top-pages"></div>

      <div id="topic1" className="body left">
        <div className="topic-blurb">
          <div className="blurb-header">
            Forest Management Plans
          </div>
          <div className="divider"></div>
          <div className="blurb">
            <p>
              <ul>
                <li>Locating/Facilitating Grant Funding for Restoration Projects using FMPs (Forest Management Plans)
                  <ul>
                    <li>Two types of FMPs are used in Oregon
                      <ul>
                        <li>Forest Management Plans through NRCS (Natural Resources Conservation Service). See below for commonly funded practices:
                          <ul>
                            <li>Fire Fuels Reduction Practices
                              <ul>
                                <li>Fuels Reduction Thinning</li>
                                <li>Prescribed Burning</li>
                                <li>Fire Fuel Breaks</li>
                                <li>Tree and Shrub Pruning</li>
                              </ul>
                            </li>
                            <li>Planting Site Preparation</li>
                            <li>Planting of Trees and Shrubs</li>
                          </ul>
                        </li>
                        <li>Forest Management Plans through a cooperative agreement with ODF (Oregon Department of Forestry) and USDA Forest Service. See below for commonly funded practices:
                          <ul>
                            <li>Stream Restoration Projects through OWEB (Oregon Water Enhancement Board)</li>
                            <li>Fuels Reduction Projects and Fire Fighting Access Roads</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div className="topic1-picture topic-picture natural-resource"></div>
      </div>

      <div id="topic2" className="body right">
        <a
          href="./images/LandAndTimberManagment.jpg"
          target="_blank"
          className="topic1-picture topic-picture land-n-timber"
        >
          <div>Click Here for Full Map</div>
          <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
        </a>

        <div className="topic-blurb">
          <div className="blurb-header">Land & Timber Management</div>
          <div className="divider"></div>
          <div className="blurb">
            <p>
              <ul>
                <li>GIS and Mapping services</li>
                <li>Timber Cruising</li>
                <li>
                  Timber Value Appraisals
                  <ul>
                    <li>
                      This information can be used for purchasing and selling
                      timber and timberland, resolving disputes, or estate
                      evaluations.
                    </li>
                  </ul>
                </li>
                <li>GPS Services</li>
                <li>Stocking Surveys</li>
                <li>Harvest Unit & Road Design</li>
                <li>Economic Feasibility Studies</li>
                <li>
                  Boundary Line Maintenance
                  <ul>
                    <li>
                      Brush clearing, repainting, and maintaining survey markers
                      - note that we are not licensed surveyors, so we can only
                      do this on existing recorded surveys.
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div id="topic3" className="body left">

        <div className="topic-blurb">
          <div className="blurb-header">Silviculture & Reforestation Planning</div>
          <div className="divider"></div>
          <div className="blurb">
            <p>
              <ul>
                <li>Seed & Seedling Acquisition</li>
                <li>
                  Landowner Assistance with Hiring Contractors & Writing
                  Contracts
                  <ul>
                    <li>Herbicide Application</li>
                    <li>Planting</li>
                    <li>Slash Burning</li>
                  </ul>
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div className="topic3-picture topic-picture reforestation"></div>
      </div>

      <div id="footer-page">
        <div className="footer-address">
          <span>P.O. Box 4690</span>
          <span>Salem, OR 97302</span>
          <span>Office Hours: 8AM - 4PM PST</span>
        </div>

        <div className="footer-top">
          <a href="#header-page">
            <FontAwesomeIcon icon={faCircleUp} />
          </a>
        </div>
      </div>
    </>
  );
}

export default Services;
