import React, { useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUp } from "@fortawesome/free-solid-svg-icons";

function Contact() {
  const form = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const header = document.querySelector("#header-page");
    header.style.background = "var(--accent1)";
    header.style.position = "relative";
  });

  const sendEmail = (e) => {
    e.preventDefault();
    //selectors
    const nameInput = document.querySelector(`input[type="text"]`);
    const emailInput = document.querySelector(`input[type="email"]`);
    const messageInput = document.querySelector(`textarea`);
    const submitButton = document.querySelector(`input[type="submit"]`);
    //changes submit button until it's sent
    submitButton.value="Sending...";
    submitButton.style.fontStyle = "italic";
    submitButton.style.background = "var(--accent3)";
    submitButton.disabled = "true";
    //sends email
    emailjs
      .sendForm("service_0th5ldj", "template_4firk74", form.current, {
        publicKey: "PhaL0T59-7KZ_-tIL",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          submitButton.style.fontStyle = "normal";
          submitButton.style.background = "var(--accent1)";
          submitButton.value = "Send";
          submitButton.disabled = "false";
          alert("Message sent successfully! Thank you.");
        },
        (error) => {
          console.log("FAILED...", error.text);
          submitButton.style.fontStyle = "normal";
          submitButton.style.background = "var(--accent1)";
          submitButton.value = "Send";
          submitButton.disabled = "false";
          if (window.confirm("ERROR: Something went wrong. Click OK to refresh the page and try again.")) {
            navigate(0);
          }
        },
      );
    //clears fields just in case
    nameInput.value = "";
    emailInput.value = "";
    messageInput.value = "";
  };

  return (
    <>
      <div id="header-page">
        <Link to="/">
          <div className="header-title">
            <div className="logo small">
              <img src="./images/SmallLogoSVG.svg" alt="Business logo" />
            </div>
            <div>
              <div className="name">Next Generation Forestry</div>
              <div className="subtitle">
                Serving the Willamette Valley in Salem, OR and Surrounding Areas
              </div>
            </div>
          </div>
        </Link>

        <div className="header-links">
          <Link to="/services">Services</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/testimonials">Testimonials</Link>
        </div>
      </div>

      <div id="contact">
        <form ref={form} onSubmit={sendEmail} id="contact-form">
          <div className="blurb-header">Contact Me</div>

          <div>
            <label htmlFor="user_name">Name*:</label>
            <input type="text" name="user_name" required />
          </div>

          <div>
            <label htmlFor="user_email">Email*:</label>
            <input
              type="email"
              name="user_email"
              pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
              required
            />
          </div>

          <div>
            <label htmlFor="message">Message*:</label>
            <textarea
              placeholder="Type your message here..."
              name="message"
              required
            ></textarea>
          </div>

          <div>
            <input type="submit" value="Send" />
          </div>
        </form>

        <div className="contact-info">
          Next Generation Forestry
          <br />
          P.O. Box 4690
          <br />
          Salem, OR 97302-3831
          <br />
          <br />
          Office Hours: 8AM - 4PM PST
        </div>
      </div>

      <div id="footer-page">
        <div className="footer-address">
          <span>P.O. Box 4690</span>
          <span>Salem, OR 97302</span>
          <span>Office Hours: 8AM - 4PM PST</span>
        </div>

        <div className="footer-top">
          <a href="#header-page">
            <FontAwesomeIcon icon={faCircleUp} />
          </a>
        </div>
      </div>
    </>
  );
}

export default Contact;
