import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import Services from "./Services";
import Testimonials from "./Testimonials";
import Contact from "./Contact";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <App />,
  },
  {
    path: "/services",
    element: <Services />,
    errorElement: <Services />,
  },
  {
    path: "/testimonials",
    element: <Testimonials />,
    errorElement: <Testimonials />,
  },
  {
    path: "/contact",
    element: <Contact />,
    errorElement: <Contact />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
